import React from 'react'
import './bannerDescription.css'

function BannerDescription() {
  return (
    <article id='bannerImage' className='d-flex align-items-center w-100'>
        <p className='fs-3 d-flex align-items-center text-center m-auto p-5 text-white rounded-4'>
        Avec la 5G, profitez d'un débit ultra-rapide pour télécharger en un instant vos contenus préférés et jouer en ligne en toute fluidité. La 5G permet également une réactivité sans précédent pour une expérience utilisateur encore plus agréable.
        </p>
    </article>
  )
}

export default BannerDescription