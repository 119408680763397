import React from 'react'
import "./titlePage.css"

function TitlePage(props) {
  return (
    <article id='formBanner' className='w-100 d-flex align-items-center'>
      <h1 className={`text-lg-nowrap text-center w-100 m-0 fw-bold ${props.title === "POLITIQUE DE CONFIDENTIALITÉ" || props.title === "MENTIONS LÉGALES" ? "text-dark" : ""}`}>
        {props.title}
      </h1>

    </article>
  )
}

export default TitlePage