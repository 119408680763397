import React, {useEffect} from "react";
import "./home.css";
import Header from "../../partials/Header/Header";
import BannerContact from "../../components/BannerContact/BannerContact";
import ButtonForCall from "../../components/ButtonForCall/ButtonForCall";
import Footer from "../../partials/Footer/Footer";
import ContainerArticles from "../../components/containerArticles/ContainerArticles";
import BannerDescription from "../../components/BannerDescription/BannerDescription";
import data from "../../assets/data/data.json";

function Home() {

   // Divise le tableau 'data' en deux parties
   const firstData = data.slice(0, Math.ceil(data.length / 2));
   const secondData = data.slice(Math.ceil(data.length / 2));


  useEffect(() => {
    window.scrollTo(0, 0); // Fait défiler la fenêtre en haut de la page
  }, []);

  return (
    <main className="d-flex flex-column align-items-center hoverflow-hidden vw-100 mb-3">
      <Header />
      <BannerContact />
      <ContainerArticles data={firstData}/>
      <BannerDescription/>
      <ContainerArticles data={secondData}/>
     
      <ButtonForCall class="buttonSize"/>
      <Footer/>
    </main>
  );
}

export default Home;
