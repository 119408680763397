import React from "react";
import "./containerArticles.css";

function ContainerArticles(props) {
  return (
    <article className="containerArticle d-lg-flex justify-content-lg-center mt-2 mb-5 py-lg-5">
      {props.data.map((item, index) => (
        <section key={index} className="description p-4 my-4 rounded-5 d-flex mx-lg-3 flex-column align-items-center justify-content-around">
          <h3 className="text-center d-block h-25">{item.titre}</h3>
          <img src={"/icones/" + item.titreImage} className="my-3" alt={item.titre} />
          <p className="h-50">{item.description}</p>
        </section>
      ))}
    </article>
  );
}

export default ContainerArticles;
