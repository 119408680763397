import React from "react";
import "./header.css";
import logoSC from "../../assets/images/Logo-SC.png";
import logoSwitch from "../../assets/images/logoSwitch.png";
import ButtonForCall from "../../components/ButtonForCall/ButtonForCall";
import { Link} from "react-router-dom";

function Header() {
  return (
    <header className="sticky-top w-100 d-flex justify-content-around align-items-center">
      <article 
      id="containerLogoCe" 
      className="d-none me-5">
          <Link
            to="/"
          >
             <img 
        src={logoSC} 
        alt="logo Switch call"/>
           
          </Link> 
         
      </article>
      <article className="d-flex align-items-center">

      <ButtonForCall/>
      <section id="containerLogoSwitch" className="d-none ms-5">
        <a href="https://www.chezswitch.fr/" rel="noreferrer" target="_blank" >

        <img src={logoSwitch} alt="logo chez switch" />
        </a>
      </section>
      </article>
    </header>
  );
}

export default Header;
