import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter } from 'react-router-dom';
import Routing from './router';

function App() {
  return (
    <BrowserRouter>
      <Routing/>
    </BrowserRouter>
  );
}

export default App;
