import React, { useEffect, useState } from "react";
import "./bannerContact.css";
import iconeBulb from "../../assets/images/iconeBulb.png";
import iconePhone from "../../assets/images/iconePhone.png";
import { motion, useAnimation } from "framer-motion";

function BannerContact() {
  const [shouldMoveButtons, setShouldMoveButtons] = useState(false);

  const handleResize = () => {
    const viewportHeight = window.innerHeight;
    setShouldMoveButtons(viewportHeight < 800);
  };
  const containerButtonsControls = useAnimation();
  useEffect(() => {
    // Ajouter un écouteur d'événement pour gérer le redimensionnement de la fenêtre
    window.addEventListener("resize", handleResize);
    // Appeler handleResize initialement pour définir l'état initial en fonction de la hauteur de l'écran
    handleResize();

    // Nettoyage de l'écouteur d'événement lors du démontage du composant
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    containerButtonsControls.start({ opacity: 1, y: 0 });
  }, [containerButtonsControls]);

  const handlePhoneClick = () => {
    // Redirige vers le numéro de téléphone
    window.location.href = "tel:0189471956";
  };

  const handleSwitchClick = () => {
    // Redirige vers le site internet chezSwitch
    window.open("https://www.chezswitch.fr/forfait-mobile/#/", "_blank");
  };

  return (
    <article className="p-0 d-flex justify-content-center row m-0 position-relative hoverflow-hidden p-0 w-100">
      <section id="containerBanner" className="p-0 w-100 m-0 row">
        <div className="d-flex m-0 flex-column align-items-center h-75 justify-content-around p-0 col-lg-6">
          <div className="d-flex m-0 flex-column align-items-center h-75 justify-content-around w-lg-75">
            <h1 className="fw-semibold text-center m-auto my-3 mx-md-0 me-md-3 d-block fw-semibold fs-2">
              Plus qu'un forfait, une connexion à l'innovation.
            </h1>
            <motion.section
              id="containerBackground"
              className="rounded-4 mt-5 bg-white p-4"
              initial={{ opacity: 0, y: 300 }}
              animate={containerButtonsControls}
              transition={{ type: "spring", damping: 50, stiffness: 200 }}
            >
              <div
                onClick={handleSwitchClick}
                className="containerContact rounded-4 d-flex justify-content-around align-items-center p-3 mb-5"
              >
                <article className="">
                  <img src={iconePhone} alt="icone d'un téléphone" />
                </article>
                <p className="fs-3 m-0 fw-semibold text-center">
                  Souscrire en ligne
                </p>
              </div>
              <div
                onClick={handlePhoneClick}
                className="containerContact rounded-4 d-flex justify-content-around align-items-center p-3"
              >
                <article>
                  <img src={iconeBulb} alt="icone d'une ampoule" />
                </article>
                <article className="d-flex flex-column align-items-center">
                  <p className="fs-3 m-0 fw-semibold text-center">
                    Souscrire par téléphone
                  </p>
                </article>
              </div>
            </motion.section>
          </div>
        </div>
      </section>
    </article>
  );
}

export default BannerContact;
