import React, { useEffect} from 'react'
import Header from '../../partials/Header/Header'
import Footer from '../../partials/Footer/Footer';
import TitlePage from '../../components/TitlePage/TitlePage';
import ArticleMentionsLegales from '../../components/ArticleMentionsLegales/ArticleMentionsLegales';

function MentionsLegales() {
    useEffect(() => {
        window.scrollTo(0, 0); // Fait défiler la fenêtre en haut de la page
      }, []);
  return (
   <main  className="d-flex flex-column align-items-center vw-100 mb-3">
    <Header/>
    <TitlePage title="MENTIONS LÉGALES"/>
    <ArticleMentionsLegales/>
    <Footer/>
   </main>
  )
}

export default MentionsLegales;